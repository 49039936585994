import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { COLOR_PRIMARY, COMPANY_NAME, NAVIGATION_LINKS } from "../constant";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar
        expand="md"
        dark
        fixed="top"
        style={{ backgroundColor: COLOR_PRIMARY }}
      >
        <Link to="/" className="navbar-brand" onClick={toggle}>
          <img
            src="/images/logo.png"
            style={{ maxHeight: "40px" }}
            alt={COMPANY_NAME}
          />
        </Link>{" "}
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <div className="mx-auto">
            <Nav className="ml-auto" navbar>
              {NAVIGATION_LINKS.map((item, index) => (
                <React.Fragment key={index}>
                  {item.children ? (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        nav
                        caret
                        className="text-white font-semi-bold"
                      >
                        {item.name}
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        dark
                        style={{ backgroundColor: "#4C7F6A" }}
                      >
                        {item.children.map((child, childIndex) => (
                          <DropdownItem key={childIndex}>
                            <Link
                              to={child.href}
                              className="dropdown-item text-white font-semi-bold"
                              onClick={toggle}
                            >
                              {child.name}
                            </Link>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ) : (
                    <NavItem>
                      <Link
                        to={item.href}
                        className="nav-link mr-3 text-white font-semi-bold"
                        onClick={toggle}
                      >
                        {item.name}
                      </Link>
                    </NavItem>
                  )}
                </React.Fragment>
              ))}
            </Nav>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
