import {
  FingerPrintIcon,
  LockClosedIcon,
  ScaleIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";

export const COLOR_PRIMARY = "#002F1C"

export const COMPANY_NAME = "Sealing Automation";

export const COMPANY_HEADER = "Trusted Leaders in Industrial Automation";

export const COMPANY_DESCRIPTION = "Leading supplier for Pneumatic Actuators, Solenoid Valve, Control Valves, Air Line Accessories, Process Valves, and more. With 18 years of experience, we provide reliable solutions at competitive prices for various industries and applications.";

export const NAVIGATION_LINKS = [
  { name: "About Us", href: "/about", current: false },
  {
    name: "Products", href: "/products", current: false, children: [
      { name: "Valve Automation", href: "/products/valve-automation", current: false },
      { name: "Actuator and Process Values", href: "/products/actuator-and-process-values", current: false },
      { name: "Manual Valves", href: "/products/manual-valves", current: false },
      { name: "Schrader Pneumatic Component", href: "/products/schrader-pneumatic-component", current: false },
      { name: "Leader Valves", href: "/products/leader-valves", current: false },
    ],
  },
  { name: "Blogs", href: "/blogs", current: false },
  { name: "Contact Us", href: "/contact", current: false },
];

export const CLIENT_LOGOS = [
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/b5c0289f-e3f5-4cad-b471-e6951a1b96c5" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/7997cc22-f0c5-437d-99ee-e9001b8827e6" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/9ea54172-d3d4-4cce-97a9-c4fedf7b05a9" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/28d9e52a-c6f1-480c-8666-21107aa16c43" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/e809328b-9a09-43da-b0f4-aeeead6a8e86" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/986d58ad-f973-4f48-9d78-29a87c9fe2d6" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/e5940c11-5957-4ab6-a290-9aed0bee10e6" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/a59c3e11-727a-4aee-a113-5edfada1462b" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/56bf9e60-073f-4683-8002-77d1bfa6f6ba" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/1826b5c6-b471-43a7-90b1-27d7ed15becb" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/2e62ebd1-41f0-46fc-95bf-1b2aa9c3a328" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/83bdd5f0-eeb6-4e17-abb7-f9b2128da1e1" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/917ab883-f6e3-4f07-8042-92f4f6bc5d39" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/82b243ed-2106-4562-813e-585e6c4138a5" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/72107f8a-ae27-436b-a1ec-84a3117ebddc" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/9b6da093-7eb6-4087-b941-eddc08773480" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/943fb4a2-9c18-4803-a375-db3ca6b30740" },
  { src: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/2cc4d965-cb13-4920-b31b-453cefa68314" }
];

export const STATS = [
  { id: 1, name: 'Satisfied Customers', value: '200 +' },
  { id: 2, name: 'Products', value: '400 +' },
  { id: 3, name: 'Years of Operation', value: '22 +' },
];

export const PRODUCTS = [
  {
    Name: "Valve Automation",
    href: "/products/valve-automation",
    Image: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/9235bfce-266c-4c10-b1dc-2c03a45fe9aa",
    Description:
      "High-quality valve automation solutions for industrial applications.",
  },
  {
    Name: "Actuator and Process Values",
    href: "/products/actuator-process-values",
    Image: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/0cbead0a-c36b-4185-9bc0-385562d23e79",
    Description: "Reliable actuators and process valves for efficient control.",
  },
  {
    Name: "Manual Valves",
    href: "/products/manual-valves",
    Image: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/cde28fca-819b-446c-aee9-a09a72a22aa3",
    Description: "Durable manual valves for precise flow control.",
  },
  {
    Name: "Schrader Pneumatic Component",
    href: "/products/schrader-pneumatic-component",
    Image: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/5f51201a-4a3f-4e09-94b4-25cacac0212d",
    Description: "Premium pneumatic components for various industrial needs.",
  },
  {
    Name: "Leader Valves",
    href: "/products/leader-valves",
    Image: "https://github.com/divyabaid16/Ecommerce_Fashion/assets/32747809/c2bad073-1692-44b8-b537-e2b11fc797d8",
    Description: "Industry-leading valves for diverse applications.",
  },
];

export const FEATURES = [
  {
    name: "Quality Focus",
    description:
      "We prioritize top-notch quality to deliver products that surpass expectations and contribute to your success.",
    icon: ScaleIcon,
  },
  {
    name: "Timely Fulfillment",
    description:
      "Count on us for timely order processing and delivery, ensuring uninterrupted operations.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Proven Track Record",
    description:
      "Over two decades of excellence, marked by successful projects and satisfied clients, attest to our reliability.",
    icon: LockClosedIcon,
  },
  {
    name: "Affordable Cost",
    description:
      "Benefit from competitive pricing that enhances cost-efficiency without compromising on quality.",
    icon: FingerPrintIcon,
  },
];

export const ABOUT = {
  companyName: "Sealing System Corporation",
  foundedYear: 2001,
  mission:
    "provide the best products in the industry at the most competitive prices with minimum lead time along with efficient sales and after-sales services.",
  visionMotto: "Quality Products made Affordable",
  visionPrinciple:
    "A satisfied customer is the best business strategy of all",
  satisfiedCustomers: 150,
  productCount: 300,
  industries: [
    "Valve Automation for Process Industries",
    "Solenoid Valve for Tyre Industries",
    "Control Valve for Steam Applications",
    "Ball Valve with Rotary Actuator for ON/OFF Applications",
    "Butterfly Valve with Rotary Actuator for Dry/Wet Applications",
    "Limit Switch for feedback",
    "Power Cylinders for proportional opening/closing applications",
    "Valve Dampers for Cement Industry",
    "Directional Control Solenoid Valve for Actuators",
    "Direct Acting Valve",
    "Knife Gate Valve for Paper and Pulp Industries",
    "Slide Gate Valve for Hopper opening and closing",
  ],
};

export const CONTACT_INFO = {
  address: [
    'Sealing System Corporation',
    'Mona Sadan, 1st Floor, Opp. Sai Baba Mandir',
    'New Laheripura Road',
    'Vadodara - 390001',
    'Gujarat, India.'
  ],
  telephone: '+91 265 2422689',
  mobile: '+91 98250 20042',
  website: 'http://www.sealingautomation.com/',
  email: 'sealsys@gmail.com'
};

export const ALL_PRODUCTS = [
  {
    id: 1,
    name: 'Category 1',
    slug: 'leader-valves',
    products: [
      {
        id: 1,
        name: 'Product 1',
        slug: 'product-1',
        features: [
          { key: 1, value: 'Feature 1 of Product 1' },
          { key: 2, value: 'Feature 2 of Product 1' }
        ],
        image_url: '/path/to/product-1-image.jpg'
      },
      {
        id: 2,
        name: 'Product 2',
        slug: 'product-2',
        features: [
          { key: 3, value: 'Feature 1 of Product 2' },
          { key: 4, value: 'Feature 2 of Product 2' }
        ],
        image_url: '/path/to/product-2-image.jpg'
      }
    ]
  },
  {
    id: 2,
    name: 'Category 2',
    slug: 'category-2',
    products: [
      {
        id: 3,
        name: 'Product 3',
        slug: 'product-3',
        features: [
          { key: 5, value: 'Feature 1 of Product 3' },
          { key: 6, value: 'Feature 2 of Product 3' }
        ],
        image_url: '/path/to/product-3-image.jpg'
      },
      {
        id: 4,
        name: 'Product 4',
        slug: 'product-4',
        features: [
          { key: 7, value: 'Feature 1 of Product 4' },
          { key: 8, value: 'Feature 2 of Product 4' }
        ],
        image_url: '/path/to/product-4-image.jpg'
      }
    ]
  }
];