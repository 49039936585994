import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faSms,
  faEnvelope,
  faHome,
} from "@fortawesome/free-solid-svg-icons";

const StickyIcons = () => {
  return (
    <div className="fixed top-1/2 right-4 transform -translate-y-1/2 flex flex-col items-center">
      <a href="/" className="bg-red-500 rounded-full p-2 md:p-3 mb-2">
        <FontAwesomeIcon
          icon={faHome}
          className="text-white text-sm md:text-lg"
        />
      </a>
      <a
        href="https://wa.me/+919825020042"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-green-500 rounded-full p-2 md:p-3 mb-2"
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          className="text-white text-sm md:text-lg"
        />
      </a>
      <a
        href="tel:+919825020042"
        className="bg-blue-500 rounded-full p-2 md:p-3 mb-2"
      >
        <FontAwesomeIcon
          icon={faPhone}
          className="text-white text-sm md:text-lg"
        />
      </a>
      <a
        href="sms:+919825020042"
        className="bg-yellow-500 rounded-full p-2 md:p-3 mb-2"
      >
        <FontAwesomeIcon
          icon={faSms}
          className="text-white text-sm md:text-lg"
        />
      </a>
      <a
        href="mailto:sealsys@gmail.com"
        className="bg-gray-500 rounded-full p-2 md:p-3"
      >
        <FontAwesomeIcon
          icon={faEnvelope}
          className="text-white text-sm md:text-lg"
        />
      </a>
    </div>
  );
};

export default StickyIcons;
