import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import NotFound from './pages/NotFound';
import Product from './components/Product';
import Contact from './pages/Contact';
import About from './pages/About';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import StickyIcons from './components/StickyIcons';

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <Navigation />
        <StickyIcons />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route
            path="/products/:category_slug/:product_slug"
            element={<ProductDetails />}
          />
          <Route path="/products/:category_slug" element={<Products />} />
          <Route path="/products" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App;
