import React, { useState } from "react";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import { Link } from "react-router-dom";
import getSlug from "../util";

const ProductCard = ({ product, category_slug }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const truncateDescription = (description) => {
    const words = description?.split(" ");
    if (words?.length > 30) {
      return words.slice(0, 30).join(" ") + "...";
    }
    return description;
  };

  return (
    <Card className="m-4 p-4" style={{ minWidth: "300px", maxWidth: "300px" }}>
      <div className="relative w-full pt-[56.25%] rounded-lg overflow-hidden">
        {!imageLoaded && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
            <div className="w-12 h-12 border-4 border-gray-300 border-t-4 border-t-blue-500 rounded-full animate-spin"></div>
          </div>
        )}
        <img
          className={`absolute inset-0 w-full h-full object-contain transition-opacity duration-300 ${
            imageLoaded ? "opacity-100" : "opacity-0"
          }`}
          src={
            category_slug
              ? `/images/${category_slug}/${getSlug(product.Name)}.png`
              : product.Image
          }
          alt={product.Name}
          onLoad={handleImageLoad}
        />
      </div>
      <CardBody>
        <CardTitle tag="h5" className="font-bold text-gray-900 mt-2 mb-4">
          {product.Name}
        </CardTitle>
        <CardText className="text-gray-700 mb-4">
          {truncateDescription(product.Description)}
        </CardText>
        {category_slug ? (
          <Link
            to={`/products/${category_slug}/${getSlug(product.Name)}`}
            className="hover:underline inline-block font-semibold leading-6 text-gray-900"
          >
            Learn more →
          </Link>
        ) : (
          <Link
            to={`/products/${getSlug(product.Name)}`}
            className="hover:underline inline-block font-semibold leading-6 text-gray-900"
          >
            Learn more →
          </Link>
        )}
      </CardBody>
    </Card>
  );
};

export default ProductCard;
