import React from "react";
import { FEATURES } from "../constant";

const Feature = () => {
  return (
    <div className="bg-gradient-to-br from-purple-100 to-purple-2000 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 relative z-10">
        <div className="mx-auto lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Unlock Your Potential
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Elevate Your Operations with Our Solutions
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Explore our key features designed to enhance your efficiency and
            drive success. From access to specialized industry knowledge to
            seamless deployment and global reach, we offer everything you need
            to excel in your endeavors.
          </p>
        </div>

        <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-4 lg:gap-y-16">
            {FEATURES.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Feature;
