import React from "react";
import HeroSection from "../components/HeroSection";
import ClientSection from "../components/ClientSection";
import Stats from "../components/Stats";
import Product from "../components/Product";
import Feature from "../components/Feature";

const Home = () => {
  return (
    <>
      {<HeroSection />}
      {<Stats />}
      {<Product />}
      {<ClientSection />}
      {<Feature />}
    </>
  );
};

export default Home;
