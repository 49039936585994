import React from "react";
import { CONTACT_INFO } from "../constant";
import ContactForm from "../components/ContactForm";
import GradientTop from "../components/GradientTop";

const Contact = () => {
  return (
    <div className="min-h-screen bg-white p-6 flex flex-col justify-center">
      <div className="container mx-auto p-8">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="flex flex-col justify-center space-y-4 lg:m-8 sm:m-0">
            <h2 className="text-3xl font-semibold mb-4">Get in Touch!</h2>
            <div>
              <iframe
                title="location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.5089574428844!2d73.20407527558189!3d22.29658337968921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc5c5290709f3%3A0x6030950ed7f52d92!2sSealing%20Automation!5e0!3m2!1sen!2sae!4v1717152849718!5m2!1sen!2sae"
                width="100%"
                height="250"
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
                className="rounded-lg shadow-md"
              ></iframe>
            </div>
            <hr />
            <div className="mt-8">
              <address className="not-italic">
                {CONTACT_INFO.address.map((line, index) => (
                  <p key={index} className="leading-tight mb-0">
                    {line}
                  </p>
                ))}
              </address>
            </div>
            <p>
              <strong>Telephone:</strong> {CONTACT_INFO.telephone}
            </p>
            <p className="m-0">
              <strong>Mobile:</strong> {CONTACT_INFO.mobile}
            </p>
            <p className="m-0">
              <strong>Website:</strong>{" "}
              <a
                href={CONTACT_INFO.website}
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                {CONTACT_INFO.website}
              </a>
            </p>
            <p className="m-0">
              <strong>Email:</strong>{" "}
              <a
                href={`mailto:${CONTACT_INFO.email}`}
                className="text-blue-500"
              >
                {CONTACT_INFO.email}
              </a>
            </p>
          </div>
          <div className="flex flex-col justify-center p-8 bg-white shadow-lg rounded-lg">
            <h3 className="text-xl font-semibold mb-4">
              We’d love to hear from you! Let’s get in touch
            </h3>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
