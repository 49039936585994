import React from "react";
import { COLOR_PRIMARY, COMPANY_NAME, NAVIGATION_LINKS } from "../constant";
import { Link } from "react-router-dom";

const Footer = () => {
  const products = NAVIGATION_LINKS.find((link) => link.name === "Products");

  return (
    <footer className={`bg-[#002F1C] text-white py-12`}>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-10 md:px-0">
        <div>
          <h3 className="text-lg font-semibold mb-4">Products</h3>
          <ul className="list-none p-0">
            {products &&
              products.children.map((child) => (
                <li key={child.name} className="mb-2">
                  <Link
                    to={child.href}
                    className="hover:underline cursor-pointer text-white text-decoration-none"
                  >
                    {child.name}
                  </Link>
                </li>
              ))}
          </ul>
        </div>

        <div className="mr-4">
          <h3 className="text-lg font-semibold mb-4">Company</h3>
          <ul className="list-none p-0">
            {NAVIGATION_LINKS.filter((link) => link.name !== "Products").map(
              (link) => (
                <li key={link.name} className="mb-2">
                  <Link
                    to={link.href}
                    className="hover:underline cursor-pointer text-white text-decoration-none"
                  >
                    {link.name}
                  </Link>
                </li>
              )
            )}
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4">Copyright</h3>
          <p>
            &copy; {new Date().getFullYear()} {COMPANY_NAME}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
