import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CLIENT_LOGOS } from "../constant";

const ClientSection = () => {
  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    arrows: false,
    dots: false,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reverseSettings = {
    ...settings,
    rtl: true,
  };

  const half = Math.ceil(CLIENT_LOGOS.length / 2);
  const firstHalf = CLIENT_LOGOS.slice(0, half);
  const secondHalf = CLIENT_LOGOS.slice(half);

  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-6 lg:px-8">
        <h2 className="text-center text-2xl font-bold leading-8 text-gray-900 mb-6">
          Our Trusted Partners
        </h2>
        <div className="mt-10">
          <Slider {...settings}>
            {firstHalf.map((logo, index) => (
              <div key={index} className="text-center">
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="h-auto max-h-12 w-auto object-contain mx-auto mb-4"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="mt-10">
          <Slider {...reverseSettings}>
            {secondHalf.map((logo, index) => (
              <div key={index} className="text-center">
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="h-auto max-h-12 w-auto object-contain mx-auto mb-4"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ClientSection;
