import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import { ALL_PRODUCTS } from "../constant";
import * as XLSX from "xlsx";
import getSlug from "../util";
import { DocumentIcon } from "@heroicons/react/24/outline";

const ProductDetails = () => {
  const { category_slug, product_slug } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const filePath = "/Products.xlsx";
        const response = await fetch(filePath);

        if (!response.ok) {
          throw new Error(
            `Failed to fetch Excel file: ${response.status} ${response.statusText}`
          );
        }

        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        const sheetName = workbook.SheetNames.find(
          (name) => name.toLowerCase().replace(/\s/g, "-") === category_slug
        );
        if (!sheetName) {
          throw new Error(`No sheet found for category: ${category_slug}`);
        }

        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet);

        const foundProduct = data.find(
          (item) => getSlug(item.Name) === product_slug
        );

        if (foundProduct) {
          setProduct(foundProduct);
        } else {
          console.error(`Product with slug '${product_slug}' not found.`);
          setProduct(null);
        }
      } catch (error) {
        console.error("Error reading Excel file:", error);
        setError(error.message || "Error reading Excel file.");
      }
    };

    fetchProductDetails();
  }, [category_slug, product_slug]);

  if (error) {
    return <NotFound />;
  }

  if (!product) {
    return <div>Loading...</div>;
  }

  const additionalDetails = Object.keys(product)
    .filter((key) => key.startsWith("__EMPTY"))
    .map((key) => product[key]);

  const pdfTexts = product.PdfText ? product.PdfText.split(";") : [];
  const pdfLinks = product.Pdf ? product.Pdf.split(";") : [];

  return (
    <div className="bg-gradient-to-br from-purple-100 to-purple-2000">
      <div className="container px-4 mt-16 min-h-[70vh]">
        <h2 className="text-2xl font-bold mb-4 text-center">{product.Name}</h2>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 mb-4 p-6 flex justify-center items-center">
            <div className="text-center">
              <img
                src={`/images/${category_slug}/${product_slug}.png`}
                alt={product.Name}
                className="mb-4 h-60 object-cover"
                style={{ maxWidth: "100%", display: "block", margin: "0 auto" }}
              />
              <p className="text-lg font-bold mb-2">{product.Price}</p>
              <p className="mb-4">{product.Description}</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-6">
            {additionalDetails.length > 0 && (
              <div>
                <ul className="list-disc list-inside">
                  {additionalDetails.map((detail, index) => {
                    const colonIndex = detail.indexOf(":");
                    if (colonIndex !== -1) {
                      const boldText = detail.slice(0, colonIndex);
                      const normalText = detail.slice(colonIndex);
                      return (
                        <li key={index}>
                          <span className="font-bold">{boldText}</span>
                          {normalText}
                        </li>
                      );
                    } else {
                      return <li key={index}>{detail}</li>;
                    }
                  })}
                </ul>
              </div>
            )}
            {pdfTexts &&
              pdfTexts.map((pdfText, index) => (
                <div key={index} className="flex mt-4 mb-3 px-6">
                  <DocumentIcon className="h-6 w-6 text-gray-500 mr-2" />
                  <a
                    href={`/pdf/${pdfLinks[index].trim()}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-red-500 underline"
                  >
                    Datasheet - {pdfText.trim()}
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
