import React from "react";
import { ABOUT } from "../constant";
import GradientBottom from "../components/GradientBottom";
import GradientTop from "../components/GradientTop";

const About = () => {
  return (
    <div className="min-h-screen mt-8 bg-gradient-to-br from-purple-100 to-purple-2000">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-6">About Us</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <p className="text-lg text-gray-700 mb-4">
              <span className="font-bold">{ABOUT.companyName}</span> was founded
              in {ABOUT.foundedYear} with a view to cater to industrial
              products, mainly Valves, Pneumatic Components, and Valve
              Automation. The company provides trained manpower for
              sales/after-sales services and technical consultation in the
              selection of products. We are committed to {ABOUT.mission}
            </p>
          </div>
          <div>
            <img
              src="/images/about.png"
              alt="Company Image"
              className="w-full h-auto rounded-lg shadow-md"
              style={{ maxHeight: "300px" }}
            />
          </div>
        </div>

        <h2 className="text-3xl font-extrabold text-gray-900 mt-12 mb-4">
          Our Vision
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <img
              src="/images/vision.png"
              alt="Vision Image"
              className="w-full h-auto rounded-lg shadow-md"
              style={{ maxHeight: "300px" }}
            />
          </div>
          <div>
            <p className="text-lg text-gray-700 mb-4">
              With a motto of{" "}
              <span className="italic">"{ABOUT.visionMotto}"</span> and a vision
              to achieve this, we venture into the market to provide the best in
              the industry at the most competitive prices. We work with the
              principle{" "}
              <span className="italic">"{ABOUT.visionPrinciple}"</span>. With
              this vision, we have more than {ABOUT.satisfiedCustomers}{" "}
              satisfied customers and more than {ABOUT.productCount} products in
              our portfolio, with the numbers increasing with every successful
              order.
            </p>
          </div>
        </div>

        <h2 className="text-3xl font-extrabold text-gray-900 mt-12 mb-4">
          Industries We Serve
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <p className="text-lg text-gray-700 mb-4">
              We cater our Products and Services to various Industries with the
              following applications:
            </p>
            <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
              {ABOUT.industries.map((industry, index) => (
                <li key={index}>{industry}</li>
              ))}
            </ul>
          </div>
          <div>
            <img
              src="/images/industries.png"
              alt="Industries Image"
              className="w-full h-auto rounded-lg shadow-md"
              style={{ maxHeight: "350px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
