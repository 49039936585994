import React from "react";
import { COMPANY_DESCRIPTION, COMPANY_HEADER } from "../constant";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import GradientBottom from "./GradientBottom";
import GradientTop from "./GradientTop";

const HeroSection = () => {
  return (
    <div className="bg-gradient-to-br from-purple-100 to-purple-2000 px-6 pt-14 lg:px-8 ">
      <div className="mx-auto max-w-2xl h-[60vh] flex flex-col justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            {COMPANY_HEADER}
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {COMPANY_DESCRIPTION}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/contact"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started
            </Link>
            <HashLink
              to="#products"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </HashLink>
          </div>
        </div>
      </div>
      <div className="mt-16">
        <img
          src="/images/hero.png"
          alt="Hero"
          className="w-full h-[70vh] object-cover border border-black"
        />
      </div>
    </div>
  );
};

export default HeroSection;
