import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import NotFound from "./NotFound";
import { ALL_PRODUCTS } from "../constant";
import * as XLSX from "xlsx";

const Products = () => {
  const { category_slug } = useParams();
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("/Products.xlsx");
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        const sheetName = workbook.SheetNames.find(
          (name) => name.toLowerCase().replace(/\s/g, "-") === category_slug
        );
        if (!sheetName) {
          throw new Error(`No sheet found for category: ${category_slug}`);
        }

        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);

        setCategory(sheetName);
        setProducts(sheetData);
      } catch (error) {
        console.error("Error reading Excel file:", error);
        setError(error);
      }
    };

    fetchProducts();
  }, [category_slug]);

  return (
    <div className="bg-gradient-to-br from-purple-100 to-purple-2000">
      <div className="container px-4 mt-16">
        <h1>{category} Products</h1>

        {error && <NotFound />}
        {!products ? (
          <div>Loading...</div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {products.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                category_slug={category_slug}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
