// Product.js
import React from "react";
import { Container } from "reactstrap";
import ProductCard from "./ProductCard";
import { PRODUCTS } from "../constant";

const Product = () => {
  return (
    <div
      id="products"
      className="min-h-screen bg-gray-100 p-6 flex flex-col justify-center"
      style={{ minHeight: "50vh" }}
    >
      <Container>
        <h1 className="text-3xl font-bold text-gray-900 mb-4 text-center">
          Our Products
        </h1>
        <p className="text-gray-600 mb-6 text-center">
          Explore our wide range of high-quality products designed to meet your
          needs.
        </p>
        <div className="overflow-x-auto pb-4">
          <div className="flex flex-row flex-nowrap">
            {PRODUCTS.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Product;
